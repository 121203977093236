import React, {FC} from 'react';
import { Grid, Typography, Container } from '@mui/material';
import IndustriasDescription from '../industriasDescription/industriasDescription';
import icon7 from './assets/images/icon7.svg'
import icon6 from './assets/images/icon6.svg'
import icon5 from './assets/images/icon5.svg'
import icon4 from './assets/images/icon4.svg'
import icon3 from './assets/images/icon3.svg'
import icon2 from './assets/images/icon2.svg'
import icon1 from './assets/images/icon1.svg'
import icon8 from './assets/images/icon8.svg'
import icon9 from './assets/images/icon9.svg'
import icon10 from './assets/images/icon10.svg'
import icon11 from './assets/images/icon11.svg'
import './styles.scss'

const industriasContent: FC = () => {
  return (
    <Container className='container-content'>
      <Typography sx={{ my: 4, textAlign: { sm: 'center', md: 'left' } }} color="text.dark" variant="h2">
        Consumo Propio
      </Typography>
      <Grid container >
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon6} alt="BankIcon" />} 
            title="Bancos Hipotecarios" 
            description="Empresas que desarrollan proyectos inmobiliarios y venden nuevas viviendas."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon5} alt="BuilderIcon" />}
            title="Constructoras" 
            description="Entidades financieras que prestan dinero para la adquisición de bienes inmuebles. Este canal genera 16.000 créditos mensuales, teniendo así el mayor volumen en B2B2C en negocios del sector comercial."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon4} alt="RealEstateIcon" />}
            title="Inmobiliarias" 
            description="Empresas que tienen como fin el negocio de venta y alquiler a clientes finales de viviendas usadas."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription
            img={<img src={icon3} alt="PropTechIcon" />}
            title="PropTech" 
            description="Inmobiliarias digitales donde el cliente final puede poner su vivienda a la venta, o bien buscar propiedades para compra."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon2} alt="CompIcon" />} 
            title="Cajas de compensación" 
            description="Entidades encargadas de desembolsar créditos. También son intermediarios para la entrega de subsidios y compra de inmuebles a los empleados que tienen dicho beneficio."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon1} alt="LawIcon" />} 
            title="Oficinas de abogados del sector inmobiliario" 
            description="Oficinas que apoyan los procesos de las inmobiliarias, bancos y constructores en los procesos legales. Normalmente son quienes hacen los estudios jurídicos."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon7} alt="CompaniesIcon" />} 
            title="Empresas de administración de conjuntos residenciales y comerciales" 
            description="Empresas especializadas en administrar los servicios de edificios, conjuntos, centros comerciales y oficinas. "
          />
        </Grid>
      </Grid>
      
      <Typography sx={{ my: 4, textAlign: { sm: 'center', md: 'left' } }} color="text.dark" variant="h2">
        Reventa
      </Typography>
      <Grid  container >
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon8} alt="CashIcon" />} 
            title="Redes de efectivo" 
            description="Puntos de venta, terminales en tiendas de barrio y centros comerciales, que ofrecen la posibilidad de pagar servicios múltiples, incluyendo la compra del Certificado de Tradición y Libertad en efectivo."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon9} alt="SoftwareIcon" />}
            title="Software Inmobiliario" 
            description="Software que permite administrar la base de datos de clientes, y la gestión del negocio a las inmobiliarias."
          />
        </Grid>
      </Grid>
      <Typography sx={{ my: 4, textAlign: { sm: 'center', md: 'left' } }} color="text.dark" variant="h2">
        Otros canales
      </Typography>
      <Grid container >
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon10} alt="Icon1" />} 
            title="Gremios" 
            description="Agrupaciones o asociaciones de constructores, inmobiliairias, bancos y Proptechs, a través de los cuales podemos llegar a clientes potenciales, por medio de colaboraciones."
          />
        </Grid>
        <Grid sx={{ my: 4 }} item lg={6} sm={12}>
          <IndustriasDescription 
            img={<img src={icon11} alt="Icon2" />} 
            title="Portales Inmobiliarios" 
            description="Especialistas dedicados a la publicidad y la promoción de bienes inmuebles."
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default industriasContent;