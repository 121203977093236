import React, {FC} from 'react';
import { Grid, Typography, Container } from '@mui/material';
import './styles.scss'

interface industriasDescriptionProps {
  img: any;
  title: string;
  description: string;
}

const industriasDescription: React.FC<industriasDescriptionProps> = ({ img, title, description }) => {
  return (
    <Grid container lg={12} md={12} sm={12}
      spacing={{ xs: 0, md: 4 }}
    >
      <Grid item sm={12} md={2}>
        {img}
      </Grid>
      <Grid item sm={12} md={10}>
        <Typography color="text.dark" variant="h3">
          {title}:
        </Typography>
        <Typography color="text.dark" variant="body1">
          {description}
        </Typography>  
      </Grid>
    </Grid>
  )
}

export default industriasDescription;