import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import heroIndustrias from './assets/images/industriasHero.png';
import heroIndustriasMobile from './assets/images/industriasHeroMobile.png';
import './styles.scss'

const industriasHero: FC = () => {
  return (
    <>
      <Container sx={{mt:{xs:"none"}, pt:{xs:"none"}}}>
        <Grid container spacing={4} className="ing-index-hero__content" mt={"1rem"}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', mt:{xs:'none', sm:'4rem' ,md:'2rem'} }}>
            <Typography sx={{fontSize: '42px'}} color="text.white" gutterBottom variant="h1">
              Industrias
            </Typography>
            <Typography color="text.white" gutterBottom variant="body1" className="hero-title-industrias">
              Estamos enfocados en aquellas entidades que dan servicio en torno a la entrega de créditos y trámites para la adquisición y alquiler de vivienda comercial a clientes finales, así como el pago de servicios de inmuebles.
            </Typography>
            <Link to='/contacto'>
              <Button variant="outlined" sx={{ my: { md: 2, sm: 3 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
            </Link>
          </Grid>
          <Grid sx={{ display: { xs: 'none', md: 'block' }, height:'450px' }} item xs={12} md={6} >
            <img src={heroIndustrias} alt="intersect img" className="ing-index-hero__img" />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default industriasHero;