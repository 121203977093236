import React, {FC} from 'react';
import { Grid, Typography, Container, Card, CardContent } from '@mui/material';
import houseImg from './assets/images/hosue.png'
import squareImg from './assets/images/square.png'
import circleImg from './assets/images/circle.png'
import IndustriasContent from '../industriasContent/industriasContent';
import './styles.scss'

const industriasBenefits: FC = () => {

  const scrollToBenefits = (val: number) =>{
    window.scrollTo({
      top: val, 
      behavior: 'smooth'
    });
  };

  return (
    <Container>
      <Grid container lg={12} md={12} sm={12}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={12}
        sx={{ position: 'relative', bottom: 32 }}
        className="grid-benefits-cards-container"
      >
        <Grid item md={4} xs={12} >
          <Card className="grid-card-benefit" onClick={() => scrollToBenefits(1000)} sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
            <CardContent className="ing-kushki_card-content" >
              <img src={houseImg} alt="pointer" />
              <Typography color="text.dark" variant="h4">
                Consumo Propio
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card className="grid-card-benefit" onClick={() => scrollToBenefits(1900)} sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B', pt: 2}}>
            <CardContent className="ing-kushki_card-content">
              <img src={squareImg} alt="pointer" />
              <Typography color="text.dark" variant="h4">
                Reventa
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card className="grid-card-benefit" onClick={() => scrollToBenefits(2200)} sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 1.2}}>
            <CardContent className="ing-kushki_card-content">
              <img src={circleImg} alt="pointer" />
              <Typography color="text.dark" variant="h4">
                Otros canales
              </Typography>
            </CardContent>
          </Card>
        </Grid>        
      </Grid>
      <Grid container lg={12} md={12} sm={12}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
      <IndustriasContent />
      </Grid>
    </Container>
  )
}

export default industriasBenefits;